import { CellsDisplayRuleValueType } from '@pages/ConfiguredEntity/types'

import { ViewRow } from '@types'

export const generateClassesCellDisplayRule = (viewColumns: ViewRow[]) => {
  const cellsDisplayRule: CellsDisplayRuleValueType[] = []

  if (!viewColumns) {
    return null
  }

  viewColumns.map(column => {
    if (!column.useDisplayRule) {
      return null
    }

    const jsSetting = column?.displayRule?.jsSetting
    const isJsSetting = column?.displayRule?.isJs && jsSetting
    const styles = jsSetting?.styleSettings
    const displayRuleValues = column?.displayRule?.displayRuleValues
    const hasDisplayRuleValues = displayRuleValues && displayRuleValues?.length > 0

    if (isJsSetting) {
      styles?.forEach(style => {
        cellsDisplayRule.push({
          className: `style-setting-js-code-${style.code}`,
          columnName: column.title,
          columnCode: column.code,
          columnValue: column.value,
          displayRuleValue: '',
          styleSettings: style,
          jsCode: style.code,
        })
      })
    }

    if (hasDisplayRuleValues) {
      displayRuleValues?.forEach(displayRuleValue => {
        cellsDisplayRule.push({
          className: `style-setting-field-value-${displayRuleValue.objectFieldValue}`,
          columnName: column.title,
          columnCode: column.code,
          columnValue: column.value,
          displayRuleValue: displayRuleValue.objectFieldValue || '',
          styleSettings: displayRuleValue.styleSetting,
        })
      })
    }
  })

  return cellsDisplayRule
}
