import moment from 'moment'

import {
  CellsDisplayRuleValueProps,
  CellsDisplayRuleValueType,
} from '@pages/ConfiguredEntity/types'

import { ViewRow } from '@types'

export const getClassNameCell = (cellsDisplayRuleValueProps: CellsDisplayRuleValueProps) => {
  const {
    entityStylesNames,
    columnStylesNames,
    globalStylesNames,
    pinnedColumnCellClassName,
    displayRulesObject,
    params,
    columnsObject,
  } = cellsDisplayRuleValueProps
  const column = columnsObject[params?.field as keyof ViewRow] as ViewRow
  const cellDisplayRule = displayRulesObject[
    params?.field as keyof CellsDisplayRuleValueType
  ] as CellsDisplayRuleValueType

  let className = ''

  let codeStyles = ''

  const getStyleJSDisplayRule = (srcObj: any, script: string) => {
    const getStyleCode = new Function('srcObj', 'moment', script)
    try {
      return getStyleCode(srcObj, moment)
    } catch {
      return undefined
    }
  }

  if (column && cellDisplayRule) {
    const displayRule = column?.displayRule
    const jsSetting = displayRule?.jsSetting

    if (!displayRule?.displayRuleValues) {
      const script = jsSetting?.styleSettingScript ?? ''
      const row = params?.row

      codeStyles = getStyleJSDisplayRule(row, script)

      const isJsCell =
        cellDisplayRule.columnCode?.toString() === params?.field?.toString() &&
        column.value === params?.colDef?.columnValue &&
        cellDisplayRule.jsCode === codeStyles

      if (isJsCell) {
        className = cellDisplayRule?.className ?? ''
      }
    } else {
      const isValueCell =
        column.useDisplayRule &&
        column.displayRule &&
        column.code === params?.field &&
        cellDisplayRule.displayRuleValue === params?.value?.toString()

      if (isValueCell) {
        className = cellDisplayRule?.className ?? ''
      }
    }
  }

  return `${globalStylesNames.text} ${entityStylesNames.text} ${pinnedColumnCellClassName} ${columnStylesNames.text} ${className}`
}
