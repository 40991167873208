import { createContext, FC, useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormGenerator } from '@microservices/wiskey-react-components'
import { Box, Button, Grid, Typography } from '@mui/material'

import { useDisplayRulesCreateOrEdit } from '@pages/DisplayRulesCreateOrEdit/hooks'
import {
  DisplayRuleValueShotType,
  PageDisplayRulesType,
} from '@pages/DisplayRulesCreateOrEdit/types'
import { FormInputsType } from '@pages/FormCreateOrEdit/types'

import { PageContentLayout } from '@layouts/PageContentLayout'
import { ScriptValueDialog } from '@components/ScriptValueDialog'
import { SwitchTable } from '@components/SwitchTable'
import { TabPanel } from '@components/Tabs'

import {
  GENERATOR_INPUT_TYPE,
  MAX_INPUT_LENGTH,
  MODAL_TYPE,
  OBJECT_STATE_VALUES_COLUMNS,
} from '@constants'

export const PageDisplayRulesContext = createContext<PageDisplayRulesType>(
  {} as PageDisplayRulesType
)

export const DisplayRulesCreateOrEdit: FC = () => {
  const { t } = useTranslation()

  const {
    state: {
      showDialog,
      modalType,
      valueJs,
      fieldsSelectedObject,
      displayRuleValueID,
      showScriptValueDialogTitle,
      rows,
      editableFieldValue,
      isDirty,
      dirtyOnField,
      allDisplayRules,
    },
    data: {
      methods,
      watchIsJsDisplayRule,
      id,
      isCreate,
      objects,
      isEdit,
      displayRuleId,
      isLoadingDisplayRuleById,
      watchObject,
      isDirtyJsField,
      styleSettingsData,
      watchStylesCodes,
    },
    handlers: {
      handleCancel,
      handleSave,
      handleOpenDialog,
      handleCloseModal,
      handleEdit,
      handleDeleteDisplayRuleValue,
      handleSetShowScriptValueDialogTitle,
      setValue,
      handleSetFields,
      handleSetShowDialog,
      handleSetScriptValue,
    },
  } = useDisplayRulesCreateOrEdit()

  const contextValue: PageDisplayRulesType = {
    methods,
    id,
    isCreate,
    isEdit,
    showDialog,
    handleCloseModal,
    handleEdit,
    modalType,
    valueJs,
    displayRuleId,
    displayRuleValueID,
    handleSetFields,
    editableFieldValue,
    handleSetShowDialog,
    fields: rows,
  }

  const modelType = isCreate ? 'create' : 'edit'
  const fieldOptions = useMemo(
    () => objects?.map(obj => ({ id: obj.code, label: obj.title })),
    [objects]
  )

  const listStylesCodes = useMemo(() => {
    if (watchStylesCodes === null) {
      setValue('jsSetting.styleSettingCodes', undefined)

      return []
    }

    return styleSettingsData?.map(obj => ({
      id: obj.id,
      label: obj.code,
    }))
  }, [styleSettingsData, watchStylesCodes])

  const displayRuleFields: FormInputsType[] = [
    {
      name: 'internalId',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      loading: isLoadingDisplayRuleById,
      placeholder: t('displayRules.placeholder.internalId'),
      label: t('displayRules.label.internalId'),
      rules: {
        required: true,
        validate: value => {
          if (
            allDisplayRules &&
            allDisplayRules?.find(displayRule => displayRule.objectCode === value)
          ) {
            return `${value} ${t('displayRules.errors.alreadyUsed')}`
          }
        },
      },
      disabled: isEdit,
    },
    {
      name: 'title',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      loading: isLoadingDisplayRuleById,
      placeholder: t('displayRules.placeholder.title'),
      label: t('displayRules.label.title'),
      rules: {
        required: true,
      },
    },
    {
      name: 'object',
      inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
      placeholder: t('displayRules.placeholder.object'),
      label: t('displayRules.label.object'),
      disabled: isEdit,
      rules: {
        required: true,
      },
      autocompleteOptions: fieldOptions,
    },
    {
      name: 'jsFieldRow',
      inputs: [
        {
          name: 'isJsDisplayRule',
          inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
          labelPlacement: 'end',
          label: t('displayRules.label.javascript'),
          formLabelSx: {
            width: 104,
          },
        },
        {
          name: 'titleJsValue',
          inputType: GENERATOR_INPUT_TYPE.INPUT,
          placeholder: t('displayRules.placeholder.javascript'),
          maxLengthInput: MAX_INPUT_LENGTH,
          value: valueJs,
          readOnly: true,
          rules: {
            maxLength: MAX_INPUT_LENGTH,
          },
          formInputContainerSx: {
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            flexDirection: 'row-reverse',
          },
          formLabelSx: {
            ml: 2,
          },
          additionalBtn: {
            isEnabled: true,
            text: 'edit',
            color: 'primary',
            variant: 'contained',
            disabled: !watchIsJsDisplayRule,
            onClick: () => handleSetShowScriptValueDialogTitle(true),
          },
        },
      ],
    },
    ...(watchIsJsDisplayRule
      ? [
          {
            name: 'jsSetting.styleSettingCodes',
            inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
            placeholder: t('displayRules.placeholder.styleCodes'),
            label: t('displayRules.label.styleCodes'),
            multiple: true,
            rules: {
              required: true,
            },
            autocompleteOptions: listStylesCodes,
          },
        ]
      : []),
  ]

  const currentTab = 0

  return (
    <PageDisplayRulesContext.Provider value={contextValue}>
      <PageContentLayout>
        <Box sx={{ width: '100%' }}>
          <form>
            <FormProvider {...methods}>
              <Box p={1} pb={0}>
                <Grid container sx={{ pb: 2 }}>
                  <Typography variant='h5'>{t(`displayRules.title.${modelType}`)}</Typography>
                </Grid>
                <FormGenerator inputs={displayRuleFields} />
                {showScriptValueDialogTitle && (
                  <ScriptValueDialog
                    isShow={showScriptValueDialogTitle}
                    modalType={MODAL_TYPE.EDIT}
                    objectFields={fieldsSelectedObject}
                    value={valueJs || ''}
                    onClose={() => handleSetShowScriptValueDialogTitle(false)}
                    onSave={value => handleSetScriptValue(value)}
                  />
                )}
                <Box sx={{ p: 0, m: 0 }}>
                  <TabPanel index={0} value={currentTab}>
                    <SwitchTable
                      disablePagination
                      disableSort
                      isCrud
                      btnDisabled={watchIsJsDisplayRule || !isEdit}
                      btnText={t(`displayRules.button.addState`)}
                      columns={OBJECT_STATE_VALUES_COLUMNS}
                      disabledDeleteActionColumn={watchIsJsDisplayRule}
                      disabledEditActionColumn={watchIsJsDisplayRule}
                      loading={false}
                      showActionsColumn={true}
                      showDialog={showDialog}
                      type='display_rules'
                      rows={
                        rows as Omit<DisplayRuleValueShotType, 'id'> & {
                          id: string | number
                        } & Array<any>
                      }
                      onDelete={handleDeleteDisplayRuleValue}
                      onEdit={params => handleEdit(params.row.original.id)}
                      onShowDialog={handleOpenDialog}
                    />
                  </TabPanel>
                </Box>
              </Box>
              <Grid
                container
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                pb='5px'
              >
                <Box></Box>
                <Box>
                  <Button sx={{ mr: '10px' }} variant='outlined' onClick={handleCancel}>
                    {t('displayRules.button.close')}
                  </Button>
                  <Button
                    disabled={!isDirty && !dirtyOnField && !isDirtyJsField}
                    sx={{ mr: '18px' }}
                    variant='contained'
                    onClick={handleSave}
                  >
                    {t('displayRules.button.save')}
                  </Button>
                </Box>
              </Grid>
            </FormProvider>
          </form>
        </Box>
      </PageContentLayout>
    </PageDisplayRulesContext.Provider>
  )
}
